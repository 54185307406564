*,
*:after,
*:before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

a {
  color: inherit;
  text-decoration: none;
}
